<template>
    <div>
        <select-shop id="productCategorySelectShop" :loadingButton="loadingButton"
            v-on:getData="getProductCategory"></select-shop>
        <div v-if="isPermission">
            <CCard class="shadow-sm">
                <CCardBody>
                    <div class="row">
                        <div class="col-md-10 col-sm-9 col-7">
                            <h2 class="font-weight-normal">
                                {{ $t('manageMenuRanking') }}
                            </h2>
                        </div>
                        <div class="col-md-2 col-sm-3">
                            <CButton v-if="isGrabOrderEnabled" color="warning" block  @click="confirmSynchModal = true" >
                                <CIcon name="cil-sync"  color="light" size="sm" />
                                {{ $t('sync') }}  <img src="/img/delivery/grab.png" class="rounded-circle"
                                style="border-radius: 3px; width: 30px;" /></CButton>
                        </div>
                    </div>
                    <CAlert v-if="status === 'SUCCESS'" :color="statusColor" :show.sync="synchSuccessCounter" closeButton >
                       {{ synchStatusText }}  
                        <!-- <CProgress :max="5"  :value="synchSuccessCounter" height="5px"  color="success" animate /> -->
                    </CAlert>
                    <CAlert v-else :color="statusColor" :show.sync="synchSuccessCounter" :closeButton="status === 'fail'" >
                       {{ synchStatusText }} ...
                       <p v-if="status === 'fail'" style="font-size: small;" class="mb-0" >{{statusDescription}}</p>
                        <CProgress v-if="status !== 'fail'" :max="10" :value="synchSuccessCounter" height="10px" :color="statusColor" animate striped />
                    </CAlert>
                    <hr />
                    <CRow>
                        <CCard style="box-shadow: 0 0 0 1px #e5e5e5; background: whitesmoke;" class="col-2">
                            <h5 class="font-weight-normal mt-3 mb-2">{{ $t('category') }}</h5>
                            <CCardBody class=" p-0 pt-3 pr-3 pl-2 content-container">
                                <div>
                                    <div style="height: 500px;">
                                        <div class="cat-list mb-2">
                                            <CButton color="success" block
                                                :class="['cat-btn', 'me-2', 'cat-item', 'font-weight-normal', 'responsive-text', activeIndex === '' ? 'btn-outline-success' : 'default']"
                                                :style="{
                                                    backgroundColor: activeIndex === '' ? 'white' : '#29b289',
                                                    border: activeIndex === '' ? '2px solid #29b289' : 'none',
                                                    '--hover-color': generateLighterColor(getColor(1)),
                                                    fontSize: getResponsiveFontSize($t('favorite')),
                                                    color: activeIndex === '' ? '#29b289' : 'white',
                                                    boxShadow: `0px 4px 10px #29b289`
                                                }" @click="setActive('')">
                                                <span class="text-wrap">{{ $t('favorite') }}</span>
                                            </CButton>
                                        </div>
                                        <VueDraggable ref="el" v-model="data" class="cat-list " @end="updateSequence"  :disabled="!isEditData">
                                            <CButton v-for="(item) in data" :key="item.objectId ? item.objectId : item.sequence"
                                                v-if="item.sequence !== 999" block :class="['cat-btn', 'me-2', 'cat-item', 'font-weight-normal', 'responsive-text', { 'active-btn': activeIndex === item.objectId },
                                                ]" :style="{
                                                backgroundColor: activeIndex === item.objectId ? 'white' : getColor(item.indexColor),
                                                border: activeIndex === item.objectId ? `2px solid ${getColor(item.indexColor)}` : 'none',
                                                '--hover-color': generateLighterColor(getColor(item.indexColor)),
                                                fontSize: getResponsiveFontSize(item.name),
                                                color: activeIndex === item.objectId ? getColor(item.indexColor) : 'white',
                                                boxShadow: `0px 4px 10px ${generateBoxShadowColor(getColor(item.indexColor))}`
                                            }" :disabled="item.sequence === 999" @click="setActive(item.objectId)">
                                                <span class="text-wrap">{{ item.name }}</span>
                                            </CButton>
                                        </VueDraggable>
                                        <div class="cat-list mt-2" v-if="uncategory !== null">
                                            <CButton color="success" block :class="['cat-btn', 'me-2', 'cat-item', 'font-weight-normal', 'responsive-text',
                                                activeIndex === '' ? 'btn-outline-success' : 'default'
                                            ]" @click="setActive(uncategory.objectId)" :style="{
                                                backgroundColor: activeIndex === uncategory.objectId ? 'white' : '#29b289',
                                                border: activeIndex === uncategory.objectId ? '2px solid #29b289' : 'none',
                                                '--hover-color': generateLighterColor(getColor(1)),
                                                fontSize: getResponsiveFontSize(uncategory.name),
                                                color: activeIndex === uncategory.objectId ? '#29b289' : 'white',
                                                boxShadow: `0px 4px 10px #29b289`
                                            }">
                                                {{ uncategory.name }}
                                            </CButton>
                                        </div>
                                    </div>
                                    <!-- ... -->
                                </div>
                            </CCardBody>
                        </CCard>
                        <CCard  style="box-shadow: 0 0 0 1px #e5e5e5; background: whitesmoke;" class="col-10">
                            <!-- <h5 class="font-weight-normal mt-2 mb-2">{{ $t('product') }}</h5> -->
                            <div class="row mt-2">
                                <div class="col-md-9 col-sm-7 col-7">
                                    <h5 class="font-weight-normal mt-2 mb-2">{{ $t('product') }}</h5>
                                </div>
                                <CCol class="">
                                    <div class="btn-group btn-group-toggle row view-list" data-toggle="buttons">
                                        <label class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                                            style="padding: 0%;" :class="{ active: showView === 'list' }">
                                            <input type="radio" name="options" id="option1" autocomplete="off"
                                                class="custom-control-input cursor" :value="showView === 'list'"
                                                :active="showView === 'list'" :checked="this.showView === 'list'"
                                                @change="showView = 'list'" />
                                            <div class="mt-1"><i class="fi fi-rr-list" style="font-size: 14px;"></i>
                                            </div>

                                        </label>
                                        <label class="btn form-check-label col-lg-6 col-6 btn-ghost-secondary view-icon"
                                            style="padding: 0%;" :class="{ active: showView === 'grid' }">
                                            <input type="radio" name="options" id="option1" autocomplete="off"
                                                class="custom-control-input cursor" :value="showView === 'grid'"
                                                :active="showView === 'grid'" :checked="this.showView === 'grid'"
                                                @change="showView = 'grid'" />
                                            <div class="mt-1"><i class="fi fi-rr-apps" style="font-size: 14px;"></i>
                                            </div>
                                        </label>
                                    </div>
                                </CCol>
                            </div>
                            <CCardBody class="content-container " v-if="showView === 'list'"  >
                                <VueDraggable v-model="productList" 
                                @end="handleDragEnd" ref="el"  
                                @move="handleAutoScroll" 
                                @start="handleDragStart"
                                @dragend="handleDragEnd" 
                                :disabled="!isEditData"
                                :animation="150"  class=" list-group text-dark font-weight-normal position-responsive mb-3" style="background-color: #fefefe;">
                                    <div v-for="(item ,index) in productList" :key="item.objectId" style="width: 100%;"  class="list-group-item  list-group-item-action position-relative cursor-move h6 mb-0">
                                        <table style="width: 100%">
                                            <tr>
                                                <td style="width: 45px;">
                                                    <img class=" rd img-fluid" v-if="item.remoteImagePath"
                                                        :src="item.remoteImagePath" :style="{
                                                            'aspect-ratio': '4 / 3'
                                                        }"  />
                                                    <div v-else class="image-placeholder" :style="{
                                                        'aspect-ratio': '4 / 3',
                                                        mixHeight: '200px;',
                                                        backgroundColor: getColor(item.indexColor),
                                                        'border-radius': '3px'
                                                    }"></div>
                                                </td>
                                                <td style="width: 60%;" ><h6 class="pl-2 mb-0 text-dark font-weight-normal ">{{ item.SKUName }} </h6></td>
                                                <td class="text-right font-weight-normal text-success">฿ {{ getPrice(item.SKUPrice) }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </VueDraggable>
                                <div class="more-button-container"
                                    v-if="meta_data.current_page != meta_data.last_page && meta_data.last_page != 0">
                                    <CButton id="selectShopLoadingButton" v-if="loadingNewpage" block color="dark" variant="outline"
                                        disabled>
                                        <CSpinner id="selectShopSpinner" color="white" size="sm" />
                                    </CButton>
                                    <CButton v-if="!loadingNewpage && activeIndex !== ''" block color="dark" variant="outline"
                                        class="more-button" @click="getAllSKUByCategory(meta_data.current_page + 1)">
                                        {{$t('moreproduct')}}
                                    </CButton>
                                    <CButton v-if="!loadingNewpage && activeIndex == ''" block color="dark" variant="outline"
                                        class="more-button" @click="getAllSKUByFavorite(meta_data.current_page + 1)">
                                        {{ $t('moreproduct') }}
                                    </CButton>
                                </div>
                            </CCardBody>
                            <CCardBody v-if="showView === 'grid'"  class="content-container  ">

                                <div>
                                    <VueDraggable ref="el" v-model="productList" 
                                        class="row" 
                                        @end="handleDragEnd"  
                                        @move="handleAutoScroll"
                                        @dragend="handleDragEnd" 
                                        @start="handleDragStart" 
                                        :disabled="!isEditData">


                                        <div v-for="(item, index) in productList"
                                            :key="item.objectId || `product-${index}`"
                                            class="product-grid pt-0 pb-0 pr-2 pl-2">
                                            <CCard class="list cursor">
                                                <img class="card-img-top rd img-fluid" v-if="item.remoteImagePath"
                                                    :src="item.remoteImagePath" :style="{
                                                        'aspect-ratio': '4 / 3'
                                                    }" />
                                                <div v-else class="card-img-top rd img-fluid" :style="{
                                                    'aspect-ratio': '4 / 3',
                                                    backgroundColor: getColor(item.indexColor),
                                                    'border-radius': '3px'
                                                }"></div>
                                                <CCardBody style="padding: 0.2rem">
                                                    <table style="width: 100%">
                                                        <tr>
                                                            <td valign="top">
                                                                <h6 class="text-dark font-weight-normal limit-2">
                                                                    {{ item.SKUName }}
                                                                    <img v-if="item.sourceGRAB === 'GRAB'"
                                                                        src="/img/delivery/grab.png"
                                                                        class="rounded-circle" style="width: 18px;" />
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                        <tr style="border-top: 1px solid #ececec">
                                                            <td style="width: 90%;">
                                                                <h6 class="font-weight-normal text-success mt-2">
                                                                    ฿ {{ getPrice(item.SKUPrice) }}
                                                                </h6>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </CCardBody>
                                            </CCard>
                                        </div>
                                    </VueDraggable>
                                </div>
                                <div class="more-button-container"
                                    v-if="meta_data.current_page != meta_data.last_page && meta_data.last_page != 0">
                                    <CButton id="selectShopLoadingButton" v-if="loadingNewpage" block color="dark" variant="outline"
                                        disabled>
                                        <CSpinner id="selectShopSpinner" color="white" size="sm" />
                                    </CButton>
                                    <CButton v-if="!loadingNewpage && activeIndex !== ''" block color="dark" variant="outline"
                                        class="more-button" @click="getAllSKUByCategory(meta_data.current_page + 1)">
                                        {{$t('moreproduct')}}
                                    </CButton>
                                    <CButton v-if="!loadingNewpage && activeIndex == ''" block color="dark" variant="outline"
                                        class="more-button" @click="getAllSKUByFavorite(meta_data.current_page + 1)">
                                        {{$t('moreproduct')}}
                                    </CButton>
                                </div>

                            </CCardBody>
                        </CCard>
                    </CRow>
                </CCardBody>
            </CCard>
            <modal color="success" :show.sync="confirmSynchModal" :title="$t('confirmSynch')" v-on:isSave="synchronizeMenuwithGrab" centered
                    :alertError="alertError" :loading="loadingSyns">
                    <div v-if="alertError">
                        <h4 class="text-center text-danger">{{ textError }}</h4>
                        <br />
                        <h5 class="text-center text-danger">{{ textMessage }}</h5>
                        </div>
                        <div v-else>
                        <br />
                        <h4 class="text-center">{{ $t('confirmSynch') }}</h4>
                        <br />
                    </div>
            </modal>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import local from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'
import { VueDraggable } from 'vue-draggable-plus'

export default {
    components: {
        VueDraggable,
    },
    data() {
        return {

            data: [],
            loadingButton: true,
            searchLoadingButton: true,
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: 20,
                itemCount: null,
            },
            currentSortDir: 'desc',
            currentSort: '',
            sequence: '',
            itemlist: [],
            isEdit: false,
            isActive: false,
            activeIndex: '',
            productList: [],
            svgHeights: {},
            uncategory: {},
            autoScrollInterval: null,
            loadingNewpage: false,
            showView: 'grid',
            confirmSynchModal :  false,
            loadingSyns :  true,
            uploadProgress: 0,
            synchSuccess : false,
            synchSuccessCounter : 0,
            status: "",                // Menu sync status
            statusCheckInterval: null ,// Interval for status checking
            synchStatusText: "",       // Text to display for sync status
            synchFailDescription : "",
            alertError: false,
            isGrabOrderEnabled: false,
        }
    },
    created() {
        this.getOrderShopSetting()
        this.getProductCategory()
        this.shop = this.shops.find((i) => i.objectId === this.shop)
    },
    watch: {
        shopObjectId(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getOrderShopSetting()
            }
        }
    },
    computed: {
        ...mapGetters(['shops', 'users', 'date']),
        isPermission() {
            return permis.findPermissionRead('product', this.$route.path)
            //return true
        },
        statusColor() {
            if (this.status === 'QUEUEING') {
                return 'warning';
            } else if (this.status === 'PROCESSING') {
                return 'warning';
            } else if (this.status === 'SUCCESS') {
                return 'success';
            }else if (this.status === 'fail') {
                return 'danger';
            } else {
                return 'danger'; // Default color for unknown status
            } 
        },
        isEditData() {
            return permis.findPermissionEdit('product', this.$route.path)
        },
        isDeleteData() {
            return permis.findPermissionRemove('product', this.$route.path)
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        uid() {
            let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
            return shop.uid
        },
        itempage() {
            return this.meta_data.current_page * 50 - 50
        },
        fields() {
            if (this.isMenuEnabled !== '') {
                if (this.isEditData) {
                    return [
                        {
                            key: 'num',
                            label: '#',
                            sorter: false,
                            _style: 'width:5%',
                            _classes: 'text-dark font-weight-normal'
                        },
                        {
                            key: 'name',
                            label: this.$i18n.t('category'),
                            sorter: false,
                            _style: 'width:45%',
                            _classes: 'text-dark font-weight-normal'
                        },
                        {
                            key: 'color',
                            label: this.$i18n.t('bgColor'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'icon',
                            label: this.$i18n.t('icon'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'enabled',
                            label: this.$i18n.t('active'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'isMenu',
                            label: this.$i18n.t('isMenu'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'action',
                            label: '',
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                    ]
                } else {
                    return [
                        {
                            key: 'num',
                            label: '#',
                            sorter: false,
                            _style: 'width:5%',
                            _classes: 'text-dark font-weight-normal'
                        },
                        {
                            key: 'name',
                            label: this.$i18n.t('category'),
                            sorter: false,
                            _style: 'width:45%',
                            _classes: 'text-dark font-weight-normal'
                        },
                        {
                            key: 'color',
                            label: this.$i18n.t('bgColor'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'icon',
                            label: this.$i18n.t('icon'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'enabled',
                            label: this.$i18n.t('active'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'isMenu',
                            label: this.$i18n.t('isMenu'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                    ]
                }
            } else {
                if (this.isEditData && !this.isEcommerce) {
                    return [
                        {
                            key: 'num',
                            label: '#',
                            sorter: false,
                            _style: 'width:5%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'name',
                            label: this.$i18n.t('category'),
                            sorter: false,
                            _style: 'width:55%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'color',
                            label: this.$i18n.t('bgColor'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'icon',
                            label: this.$i18n.t('icon'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'enabled',
                            label: this.$i18n.t('active'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'action',
                            label: '',
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                    ]
                } else if (this.isEcommerce) {
                    return [
                        {
                            key: 'num',
                            label: '#',
                            sorter: false,
                            _style: 'width:5%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'name',
                            label: this.$i18n.t('category'),
                            sorter: false,
                            _style: 'width:30%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'color',
                            label: this.$i18n.t('bgColor'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'icon',
                            label: this.$i18n.t('icon'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'enabled',
                            label: this.$i18n.t('active'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                    ]
                } else {
                    return [
                        {
                            key: 'num',
                            label: '#',
                            sorter: false,
                            _style: 'width:5%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'name',
                            label: this.$i18n.t('category'),
                            sorter: false,
                            _style: 'width:45%',
                            _classes: 'font-weight-normal',
                        },
                        {
                            key: 'color',
                            label: this.$i18n.t('bgColor'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'icon',
                            label: this.$i18n.t('icon'),
                            _classes: 'text-center text-dark font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                        {
                            key: 'enabled',
                            label: this.$i18n.t('active'),
                            _classes: 'text-center font-weight-normal',
                            sorter: false,
                            _style: 'width:10%',
                        },
                    ]
                }
            }
        },
        colorList() {
            let data = []
            for (let i = 0; i < 30; i++) {
                data.push({
                    color: util.generateColor(i),
                    value: i,
                })
            }
            return data
        },
    },
    methods: {
        getProductDafault() {
            if(this.isEditData) {
                if (this.activeIndex === '') {
                    this.getAllSKUByFavorite()
                } else {
                    this.getAllSKUByCategory()
                }
            }
        },
        handleDragStart(event) {
            this.stopAutoScroll(event); // Ensure auto-scroll doesn't persist from previous drags
            document.body.style.userSelect = "none";
        },
        handleAutoScroll(event) {
            const container = this.$refs.el.$el; // Get the draggable container
            const scrollThreshold = 50; // Distance from bottom to trigger scroll
            const scrollSpeed = 5; // Pixels per step
            if (!container) return;
            const { clientHeight, scrollHeight, scrollTop } = container;
            // Detect if dragging near the bottom
            if (scrollHeight - scrollTop - clientHeight < scrollThreshold) {
                if (!this.autoScrollInterval) {
                    this.autoScrollInterval = setInterval(() => {
                        container.scrollTop += scrollSpeed;
                    }, 50);
                }

            } else {
                this.stopAutoScroll(event); // Stop scrolling when dragging inside the normal area
            }
    },
        updateProductSequence(event) {
            const dataEvent = event.data
            this.$nextTick(() => {
                // const movedItem = this.productList[event.newIndex]; // The dragged item
                const shopObjectId = this.shopObjectId
                const headers = { shopObjectId: shopObjectId };
                const params = {
                    shopObjectId: shopObjectId,
                }
                const data = {
                    shopObjectId: shopObjectId,
                    objectId: dataEvent.objectId,
                    itemSequence: event.newIndex + 1,
                    SKUName: dataEvent.SKUName,
                }
                
                local({
                    url: "/api/v3.0/productsku/sequence",
                    params: params,
                    data: data,
                    headers: headers,
                    method: "PATCH",
                })
                    .then((response) => {
                        setTimeout(() => {
                            this.productList = this.productList.map((item, index) => {
                                if (index === event.newIndex) {
                                    return {
                                        ...item,
                                        itemSequence: event.newIndex + 1,
                                    };
                                }
                                return item;
                            });
                        }, 1000)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            });
        },
        updateFavoriteIndex(event){
            const dataEvent = event.data
            this.$nextTick(() => {
                const shopObjectId = this.shopObjectId
                const headers = { shopObjectId: shopObjectId };
                const params = {
                    shopObjectId: shopObjectId,
                }
                const data = {
                    shopObjectId: shopObjectId,
                    objectId: dataEvent.objectId,
                    favoriteIndex: event.newIndex + 1,
                    SKUName: dataEvent.SKUName,
                }

                local({
                    url: "/api/v3.0/productsku/favorite",
                    params: params,
                    data: data,
                    headers: headers,
                    method: "PATCH",
                })
                    .then((response) => {
                        setTimeout(() => {
                            this.productList = this.productList.map((item, index) => {
                                if (index === event.newIndex) {
                                    return {
                                        ...item,
                                        itemSequence: event.newIndex + 1,
                                    };
                                }
                                return item;
                            });
                        }, 1000)
                    })
                    .catch((error) => {
                        console.log(error)
                    })

            });
            
        },
        stopAutoScroll(event) {
            if (this.autoScrollInterval) {
                clearInterval(this.autoScrollInterval);
                this.autoScrollInterval = null;
            }
            if (this.activeIndex === '') {
                this.updateFavoriteIndex(event); // ถ้า activeIndex เป็นค่าว่าง เรียก updateFavoriteIndex()
            } else {
                this.updateProductSequence(event); // ถ้า activeIndex มีค่า เรียก updateProductSequence()
            }
        },

        handleDragEnd(event) {
            this.stopAutoScroll(event);
            document.body.style.userSelect = ""
        },
        startAutoScroll(container, speed) {
            if (!this.autoScrollInterval) {
                this.autoScrollInterval = setInterval(() => {
                    if (!container) return;
                    container.scrollTop += speed;
                }, 30);
            }
        },
        getPrice(price) {
            return util.convertCurrency(price)
        },
        setActive(index) {
            this.activeIndex = index; // Set active button on click
            this.getProductDafault()
        },
        getColor(indexColor) {
            return util.generateColor(indexColor)
        },
        handleDragEnd(event) {
            this.stopAutoScroll(event);
            document.body.style.userSelect = ""
        },
        startAutoScroll(container, speed) {
            if (!this.autoScrollInterval) {
                this.autoScrollInterval = setInterval(() => {
                    if (!container) return;
                    container.scrollTop += speed;
                }, 30);
            }
        },
        generateLighterColor(rgba) {
            let matches = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]*)?\)/);
            if (!matches) return rgba;

            let r = Math.min(255, parseInt(matches[1]) + 30);
            let g = Math.min(255, parseInt(matches[2]) + 30);
            let b = Math.min(255, parseInt(matches[3]) + 30);
            let a = matches[4] ? parseFloat(matches[4]) : 1;

            return `rgba(${r}, ${g}, ${b}, ${a * 0.8})`;
        },
        getResponsiveFontSize(text) {
            if (!text) return "16px"; // Default font size if text is undefined or empty
            return text.length > 10 ? "12px" : "16px";
        },
        generateBoxShadowColor(rgba) {
            let matches = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),?\s*([\d\.]*)?\)/);
            if (!matches) return "rgba(0, 0, 0, 0.5)"; // Default shadow color

            let r = matches[1];
            let g = matches[2];
            let b = matches[3];

            return `rgba(${r}, ${g}, ${b}, 0.6)`; // Adjust opacity for soft shadow
        },
        sorting(key) {
            this.currentSort = key
            this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
            this.isActive = !this.isActive
        },
        getProductCategory() {
            this.searchLoadingButton = false
            this.loadingButton = false
            const shopObjectId = this.shopObjectId
            const params = { shopObjectId: shopObjectId }
            const headers = { shopObjectId: shopObjectId }
            local({
                url: '/api/v3.0/category/all',
                params: params,
                headers: headers,
                method: 'GET',
            }).then((res) => {
                this.data = res.data.data
                this.uncategory = this.data.find(item => item.sequence === 999) || null;
                this.getProductDafault()
                this.searchLoadingButton = true
                this.loadingButton = true
            })
        },
        updateSequence(event) {
            this.$nextTick(() => {
                // const movedItem = this.data[event.newIndex]; // The dragged item
                const dataEvent = event.data

                const shopObjectId = this.shopObjectId
                const headers = { shopObjectId: shopObjectId };
                const params = {
                    shopObjectId: shopObjectId,
                }
                const data = {
                    shopObjectId: shopObjectId,
                    objectId: dataEvent.objectId,
                    sequence: event.newIndex + 1,
                    name: dataEvent.name,
                }
                local({
                    url: "/api/v3.0/category/sequence",
                    params: params,
                    data: data,
                    headers: headers,
                    method: "PATCH",
                })
                    .then((response) => {
                        setTimeout(() => {
                            this.getProductCategory()
                        }, 1000)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                //const previousItem = this.data[event.newIndex - 1]; // The item before it
                // Ensure there is a previous item, otherwise set sequence to 1
                //let newSequence = previousItem ? previousItem.sequence + 1 : 1;
                // Update sequence of the moved item
                //this.$set(movedItem, "sequence", newSequence);
                //  console.log(`Moved item: ${movedItem.name} (New sequence: ${movedItem.sequence})`);
                // console.log(this.data); // Check updated sequence in the console
            });
        },
        setImageRef(el, index) {
            if (el) {
                this.$nextTick(() => {
                    this.updateSvgHeight(index);
                });
            }
        },
        updateSvgHeight(index) {
            this.$nextTick(() => {
                const imgElement = this.$refs.imageRef?.[index];
                if (imgElement) {
                    this.$set(this.svgHeights, index, `${imgElement.clientHeight}px`);
                } else {
                    this.$set(this.svgHeights, index, "200px;"); // Default height
                }
            });
        },
        getAllSKUByCategory(page = this.meta_data.page || 1) {
            this.searchLoadingButton = false;
            this.loadingButton = false;
            const shopObjectId = this.shopObjectId;
            const headers = { shopObjectId: shopObjectId };
            let params = {
                shopObjectId: shopObjectId,
                page: page,
                categoryObjectId: this.activeIndex,
                limit: this.meta_data.limit,
            };
            local({
                url: "/api/v3.0/productsku/bycategory",
                params: params,
                headers: headers,
                method: "GET",
            }).then((res) => {
                this.productList = page === 1 ? res.data.data : [...this.productList, ...res.data.data];
                if (res.data.paginate.items == undefined) {
                    this.countProduct = 0;
                    this.meta_data.items = 0;
                } else {
                    this.countProduct = res.data.paginate.items;
                    this.meta_data.items = res.data.paginate.items;
                }
                this.meta_data.last_page = res.data.paginate.pageCount;
                this.meta_data.current_page = res.data.paginate.currentPage;
                this.meta_data.itemCount = res.data.paginate.itemCount;
                this.meta_data.limit = res.data.paginate.perPage;
                this.countlist = res.data.length;
                this.searchLoadingButton = true;
                this.loadingButton = true;
            });
        },
        getAllSKUByFavorite(page = this.$route.query.page || 1) {
            this.searchLoadingButton = false;
            this.loadingButton = false;
            const shopObjectId = this.shopObjectId;
            const headers = { shopObjectId: shopObjectId };
            let params = {
                shopObjectId: shopObjectId,
                page: page,
                limit: this.meta_data.limit,
            };
            local({
                url: "/api/v3.0/productsku/favorite",
                params: params,
                headers: headers,
                method: "GET",
            }).then((res) => {
                this.productList = page === 1 ? res.data.data : [...this.productList, ...res.data.data];
                if (res.data.paginate.items == undefined) {
                    this.countProduct = 0;
                    this.meta_data.items = 0;
                } else {
                    this.countProduct = res.data.paginate.items;
                    this.meta_data.items = res.data.paginate.items;
                }
                this.meta_data.last_page = res.data.paginate.pageCount;
                this.meta_data.current_page = res.data.paginate.currentPage;
                this.meta_data.itemCount = res.data.paginate.itemCount;
                this.meta_data.limit = res.data.paginate.perPage;
                this.countlist = res.data.length;
                this.searchLoadingButton = true;
                this.loadingButton = true;
            });
        },
        menuSyncStatus(){
            this.loadingSyns = false
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }
            local({
                method: 'GET',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync/status',
            })
            .then((res) => {
                if (res.data.error.code == 0) {
                    this.status = res.data.data.status;

                    // Show alerts based on the status
                    if (this.status === 'QUEUEING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchQueueing')  
                        // alert('The menu synchronization is currently in QUEUEING. Please wait...');
                    } else if (this.status === 'PROCESSING') {
                        this.synchSuccessCounter  = 12
                        this.synchSuccess = false,
                        this.synchStatusText = this.$i18n.t('synchProcessing')  
                        // alert('The menu synchronization is in progress. Please be patient.');
                    } else if (this.status === 'SUCCESS') {
                        this.synchSuccessCounter  = 5
                        this.synchSuccess = true,
                        this.synchStatusText = this.$i18n.t('synchSuccess')  

                        // alert('The menu synchronization is complete!');
                        // Stop the polling logic if needed
                        this.loadingSyns = true
                        clearInterval(this.syncInterval);
                    }else if (this.status === 'FAILED') {
                        this.status = "fail";
                        this.synchSuccessCounter  = 5
                        this.synchStatusText = this.$i18n.t('synchFail') 
                        this.statusDescription = this.$i18n.t('synchFailDescription')
                        clearInterval(this.syncInterval);
                        // alert('An error occurred while checking the sync status. Please try again.');
                    }
                }else{
                    this.status = "fail";
                    this.synchSuccessCounter  = 5
                    this.synchStatusText = this.$i18n.t('synchFail') 
                    this.statusDescription = this.$i18n.t('synchFailDescription')
                    clearInterval(this.syncInterval);
                    // alert('An error occurred while checking the sync status. Please try again.');
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
                // alert('An error occurred while checking the sync status. Please try again.');
            });
        },
        synchronizeMenuwithGrab(){
            this.loadingSyns = false
            this.uploadProgress = 60
            const shopObjectId = this.shopObjectId
            const headers = { 
                shopObjectId: shopObjectId 
            }
            let params = {
                shopObjectId: this.shopObjectId,
            }

            local({
                method: 'POST',
                headers :headers,
                params: params,
                url: '/api/v3.0/grab/menu/sync',
            })
            .then((res) => {                
                if(res.status === 200){
                    this.uploadProgress = 0
                    this.loadingSyns = true
                    this.confirmSynchModal = false
                    this.menuSyncStatus();
                    // Start polling to check sync status
                    this.startSyncCheck();
                }else{
                    this.status = "fail";
                    this.synchSuccessCounter  = 5
                    this.synchStatusText = this.$i18n.t('synchFail') 
                    this.statusDescription = this.$i18n.t('synchFailDescription')
                    // alert('An error occurred while checking the sync status. Please try again.');
                }
            })
            .catch((error) => {
                console.error(error);
                this.status = "fail";
                this.synchSuccessCounter  = 5
                this.synchStatusText = this.$i18n.t('synchFail') 
                this.statusDescription = this.$i18n.t('synchFailDescription')
                // alert('Failed to initiate menu synchronization. Please try again.');
            })
        },
        startSyncCheck() {
            // Initialize polling every 10 seconds until status is SUCCESS
            this.syncInterval = setInterval(() => {
                this.menuSyncStatus();
                if (this.status === 'SUCCESS') {
                    clearInterval(this.syncInterval);
                }
            }, 10000); // 10 seconds
        },
        getOrderShopSetting(){
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`
            let params = {
                shopObjectId: shopObjectId,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            local({
                url: "/api/v3.0/shop/ordershopsetting",
                params: params,
                headers: headers,
                method: "get",
            }).then((res) => {
                if(res.status ==200){
                    let isGrabOrderEnabled  = res.data.data.isGrabOrderEnabled
                    if(isGrabOrderEnabled!== undefined && isGrabOrderEnabled !== null && isGrabOrderEnabled!==''){
                        this.isGrabOrderEnabled = isGrabOrderEnabled
                    }else{
                        this.isGrabOrderEnabled = false
                    }
                }else{
                    this.isGrabOrderEnabled = false
                }
                
            }).catch((error) => {
                    console.log(error);
            });
        },

    },

}
</script>

<style>
.view-list {
    border-radius: 0.2rem;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 1px;
}

.view-icon:active {
    color: #29b289;
    background-color: white;
}

label.btn.form-check-label.col-lg-6.btn-ghost-secondary.view-icon.active {
    background-color: white;
    color: #29b289;
}

.cat-btn {
    transition: background-color 0.3s ease, font-size 0.2s ease;
    /* padding: 5px 10px; */
    height: 80px;
    /* width: 100px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: normal;
    /* Allows text to wrap */
    word-wrap: break-word;
    /* Forces wrapping if needed */
    line-height: 1.2;
    font-weight: bold;
    color: white;
    border-radius: 8px;
    overflow-wrap: break-word;
    /* Alternative for modern browsers */
}

/* Responsive font size based on content */
.responsive-text {
    font-size: clamp(12px, 1.5vw, 16px);
    /* Ensures responsiveness */
}

/* On hover and active, change color */
.cat-btn:hover,
.cat-btn:active {
    background-color: var(--hover-color) !important;
}

.cat-list {
    display: flex;
    gap: 0px;
    flex-wrap: wrap;
}

.cat-item {
    text-align: center;
}

.cat-btn {
    transition: background-color 0.3s ease;
}

.card-img-top {
    object-fit: cover;
    /* Ensures images cover the area */
    max-height: 250px;
    /* Restrict max height for uniformity */
}

.rd {
    aspect-ratio: 4 / 3;
    object-fit: cover;
}

.cat-btn:hover {
    background-color: var(--hover-color) !important;
}

.active-btn {
    background-color: white !important;
    color: inherit;
    font-weight: bold;
}

.text-wrap {
    display: block;
    width: 100%;
    text-align: center;
}

/* Icon wrapper */
.icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

@media (min-width: 992px) {
    .product-grid {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.content-container {
    height: calc(100vh - 300px);
    /* ปรับ 100px ตาม header หรือส่วนที่ต้องการลบออก */
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: 20px;
    /* Firefox */
    scrollbar-color: #c1c1c1 #f1f1f1;
    /* Scroll thumb and track color */

}

/* Webkit (Chrome, Edge, Safari) */
.content-container::-webkit-scrollbar {
    width: 20px;
    /* Adjust width */
}

.content-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Track background */
}

.content-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    /* Scrollbar color */
    border-radius: 4px;
    /* Rounded corners */
}

.content-container::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
    /* Darker on hover */
}

.content-container {
    -ms-overflow-style: scrollbar;
}


/* Responsive Design */
@media (max-width: 768px) {
    .content-container {
        height: calc(100vh - 80px);
        /* ปรับตาม mobile */
    }
}

@media (max-width: 1024px) {
    .content-container {
        height: calc(100vh - 100px);
        /* ปรับตาม iPad */
    }
}

/* iPad Portrait Mode (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
    .content-container {
        height: calc(100vh - 100px);
        /* ปรับความสูงสำหรับ iPad */
        padding: 10px;
    }

    .product-grid {
        grid-template-columns: repeat(3, 1fr);
        /* ปรับจำนวนคอลัมน์ */
    }
}

/* iPad Landscape Mode (1024px - 1366px) */
@media (min-width: 1025px) and (max-width: 1366px) {
    .product-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>